import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
import Img from '../components/block-content/image'
import FileEntry from '../components/block-content/file-entry'
import Break from '../components/block-content/break'
import { sizes, colors, breakpoints } from '../styles/variables'

export default function Release(props) {
  const {
    name,
    date,
    heading,
    subHeading,
    headingColor,
    subHeadingColor,
    link,
    linkText,
    image,
    body,
    fileTitle,
    file,
    prevPath,
    nextPath
  } = props?.pageContext

  const internal = /^\/(?!\/)/.test(link)

  return (
    <Layout>
      <SEO title={name} />
      <div css={styles}>
        <div style={{ height: '50px' }} />
        <Link className="back" to="/news">
          {'<'}&nbsp;Back&nbsp;to&nbsp;News
        </Link>
        <div className="prev-next">
          {prevPath ? (
            <Link className="prev" to={prevPath}>
              {'<'}&nbsp;Previous
            </Link>
          ) : (
            <a />
          )}
          {nextPath ? (
            <Link className="next" to={nextPath}>
              Next&nbsp;{'>'}
            </Link>
          ) : (
            <a />
          )}
        </div>
        {link && (
          <div className="link">
            {internal ? (
              <Link to={link}>{linkText}</Link>
            ) : (
              <Modal
                to={link}
                text={linkText}
                style={css`
                  a {
                    cursor: pointer;
                    text-decoration: underline;
                    color: ${colors.primary};
                    transition: 0.4s;
                    :hover {
                      color: ${colors.primaryLight};
                    }
                  }
                `}
                component={
                  <div>
                    <a>{linkText}</a>
                  </div>
                }
              />
            )}
          </div>
        )}
        {file && (
          <FileEntry node={{ title: fileTitle || 'View File', file: file }} />
        )}
        <Img node={image} />
        <h3 style={{ color: colors.secondary }}>{date}</h3>
        <h1 style={{ color: headingColor }}>{heading}</h1>
        <h2 style={{ color: subHeadingColor }}>{subHeading}</h2>
        <BlockContent className="block-body" blocks={body} />
        <small>*For full report download PDF</small>
      </div>
      <Break node={{ height: '3' }} />
    </Layout>
  )
}
const styles = css`
  padding: 0 1rem;
  @media (${breakpoints.phoneLarge}) {
    padding: 0 3rem;
    max-width: ${sizes.constrainWidth};
    margin: auto;
  }
  margin-top: 1.5rem;
  h1 {
    font-weight: 400;
    line-height: 2rem;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 3rem;
  }
  h3 {
    font-weight: 200;
  }
  a {
    text-decoration: none;
    color: ${colors.secondary};
    transition: 0.4s;
    :hover {
      color: ${colors.secondaryLight};
    }
  }
  .back {
    margin-bottom: 1rem;
  }
  .block-body {
    a {
      text-decoration: underline;
    }
  }
  .link {
    margin-bottom: 1rem;
    a {
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.primary};
      transition: 0.4s;
      :hover {
        color: ${colors.primaryLight};
      }
    }
  }
  .prev-next {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`
